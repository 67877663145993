.top {
  top: 0 !important;
}
.bottom {
  bottom: 0 !important;
}
.borderless {
  border: 0px !important;

  .modal-title {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.tooltip-inner {
  max-width: 330px !important;
  background-color: #363f4e !important;
}

.tooltip .arrow::before {
  display: none !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.text-first:first-letter {
  text-transform: uppercase;
}

h3,
.h3 {
  font-size: 1.375rem !important;
  margin-top: 14px !important;
  @media screen and (min-width: 768px) {
    font-size: 1.75rem !important;
    margin-top: 16px !important;
  }
}

.card .btn-secondary.disabled {
  background-color: #dee2e3 !important;
  border-color: #dee2e3 !important;
  color: #737373 !important;
}
